import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Network from "../Utils/Network";

const ParcalardaDegisim = () => {
  const [stateValue, setStateValue] = useState("");
  const [adetValue, setAdetValue] = useState("");
  const [result, setResult] = useState(null);

  
    const [selectedOption, setSelectedOption] = useState(1);
   
    const handleSelectChange = (event) => {
     setSelectedOption(event.target.value);
    };
   

  const handleStateChange = (event) => {
    setStateValue(event.target.value);
  };

  const handleAdetChange = (event) => {
    setAdetValue(event.target.value);
  };

  const getServiceEndpoint = () => {
    switch (selectedOption) {
      case "1":
        return "orta/direk";
      case "2":
        return "marspiyel";
      case "3":
        return "arka/camurluk";
      case "4":
        return "havuz/saci";
      case "5":
        return "arka/panel";
      case "6":
        return "tavan/saci";
      default:
        return "orta/direk";
    }
  };


  const handleHesaplaClick = async () => {
    try {
      const endpoint = getServiceEndpoint();
      const response = await Network.post(endpoint, {
        ard: stateValue,
        adet: adetValue,
      });
      console.log(response);
      setResult(response);
      
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <div className="col-9 px-0">
          <div className="navbar">
            <div className="navbar-title">ANA PARÇALARDA DEĞİŞİM</div>
            <div className="select-box">
        <select
        value={selectedOption}
        onChange={handleSelectChange}
        className="custom-select"
       >
        <option value="1">Orta Direk Değişim</option>
        <option value="2">Marspiyel Değişim</option>
        <option value="3">Arka Çamurluk</option>
        <option value="4">Havuz Saçı</option>
        <option value="5">Arka Panel</option>
        <option value="6">Tavan Saçı</option>
       </select>
      </div>
          </div>
          <div className="card-1">
          <div className="centered-inputs">
          <label className="mb-3">Bu işlemde ne kadar ard ve adet kullanımı olduğunu giriniz.</label>
            <input
              type="number"
              placeholder="Ard"
              value={stateValue}
              onChange={handleStateChange}
            />
            <input
              type="number"
              placeholder="Adet"
              value={adetValue}
              onChange={handleAdetChange}
            />
            <button onClick={handleHesaplaClick}>Hesapla</button>
            {result !== null && <p>Hesabın Sonucu: {result}</p>}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParcalardaDegisim;

