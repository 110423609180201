import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const goNav = (path) => {
        navigate(path);
    };

    return (
        <div className={"col-3 sidebar"}>
            <div>
                {/* <img className={"logo"} src="/img/logo.png" alt=""/> */}
                <div className={"mt-4"}>
                    <button
                        className={`sidebar-button ${location.pathname === '/' && 'sidebar-button-active'}`}
                        onClick={() => {
                            goNav('/');
                        }}
                    >
                        ANA PARÇALARDA DEĞİŞİM
                    </button>
                    <button
                        className={`sidebar-button ${location.pathname === '/parcalardaDuzeltme' && 'sidebar-button-active'}`}
                        onClick={() => {
                            goNav('/parcalardaDuzeltme');
                        }}
                    >
                        ANA PARÇALARDA DÜZELTME
                    </button>
                    <button
                        className={`sidebar-button ${location.pathname === '/yerAlmayanParcalar' && 'sidebar-button-active'}`}
                        onClick={() => {
                            goNav('/yerAlmayanParcalar');
                        }}
                    >
                        LİSTELERDE YER ALMAYAN PARÇALAR
                    </button>
                    <button
                        className={`sidebar-button ${location.pathname === '/boyaUygulananAksam' && 'sidebar-button-active'}`}
                        onClick={() => {
                            goNav('/boyaUygulananAksam');
                        }}
                    >
                        BOYA UYGULANAN AKSAM
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
