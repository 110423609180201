import axios from 'axios'

const Network = class NETWORK {
    constructor(axios) {
        this.network = axios.create({
            baseURL: 'https://development.codelisa.com.tr/expertise/'
             // baseURL: 'http://127.0.0.1:5055/car/'
        })
        this.network.interceptors.request.use(async config => {
            const token = localStorage.getItem('token')
            if (token) {
                config.headers.authorization = `Bearer ${token}`
            }
            return config
        })
        this.network.interceptors.response.use(null, error => {
            if (error.response.status === 401) {
                
            }
        })
    }

    get = async path => {
        return await this.network.get(path).then(r => (r.data))
    }
    patch = async (path, body) => {
        return await this.network.patch(path, body).then(r => r.data)
      }

    post = async (path, body, headers) => {
        return await this.network.post(path, body, { headers })
            .then(r => (r.data))
            .catch(e => {
                throw e
            })
    }

    getFile = async path => {
        return await this.network.get(path, { responseType: 'blob' }).then(r => (r.data))
    }
}

export default new Network(axios)