import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Network from "../Utils/Network";

const ParcalardaDuzeltme = () => {
 const [stateValue, setStateValue] = useState("");
 const [adetValue, setAdetValue] = useState("");
 const [result, setResult] = useState(null);

 const [selectedOption, setSelectedOption] = useState("1");

 const handleSelectChange = (event) => {
  setSelectedOption(event.target.value);
 };

 const handleStateChange = (event) => {
  setStateValue(event.target.value);
 };

 const handleAdetChange = (event) => {
  const inputValue = event.target.value;
  if (inputValue >= 1 && inputValue <= 5) {
    setAdetValue(inputValue);
  } else {
    setAdetValue(""); 
  }
};
 const getServiceEndpoint = () => {
  switch (selectedOption) {
   case "1":
    return "b/sase";
   case "2":
    return "b/sase/kesme";
   case "3":
    return "b/tavan/saci";
   case "4":
    return "b/orta/direk";
   case "5":
    return "b/arka/panel";
   case "6":
    return "b/havuz/saci";
   case "7":
    return "b/arka/camurluk";
   case "8":
    return "b/marspiyel";
   default:
    return "b/sase";
  }
 };


 const handleHesaplaClick = async () => {
  try {
    const endpoint = getServiceEndpoint();
    const response = await Network.post(endpoint, {
      ard: stateValue,
      expert: adetValue,
    });
    setResult(response);
    console.log(response);
  } catch (error) {
    console.error("Error:", error);
  }
};


 return (
  <div className="container-fluid">
   <div className="row">
    <Sidebar />
    <div className="col-9 px-0">
     <div className="navbar">
      <div className="navbar-title">KAYNAKLI ANA PARÇALARDA DÜZELTME</div>
      <div className="select-box">
       <select
        value={selectedOption}
        onChange={handleSelectChange}
        className="custom-select"
       >
        <option value="1">Şase Düzeltme</option>
        <option value="2">Şase Kesme</option>
        <option value="3">Tavan Saçı</option>
        <option value="4">Orta Direk</option>
        <option value="5">Arka Panel</option>
        <option value="6">Havuz Saçı</option>
        <option value="7">Arka Çamurluk</option>
        <option value="8">Marspiyel</option>
       </select>
      </div>
     </div>
     <div className="card-1">
      <div className="centered-inputs">
       <label className="mb-3">
        Bu işlemde ne kadar ard ve expert kullanımı olduğunu giriniz.
       </label>
       <input
        type="number"
        placeholder="Ard"
        value={stateValue}
        onChange={handleStateChange}
       />
       <input
        type="number"
        placeholder="Expert (1-5 arası)"
        value={adetValue}
        onChange={handleAdetChange}
       />

       <button onClick={handleHesaplaClick}>Hesapla</button>
       {result !== null && <p>Hesabın Sonucu: {result}</p>}
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default ParcalardaDuzeltme;
