// BoyaUygulananAksam.jsx

import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Network from "../Utils/Network";

const BoyaUygulananAksam = () => {
 const [stateValue, setStateValue] = useState("");
 const [adetValue, setAdetValue] = useState("");
 const [result, setResult] = useState(null);
 const [loading, setLoading] = useState(false);

 const handleStateChange = (event) => {
  setStateValue(event.target.value);
 };

 const handleAdetChange = (event) => {
  setAdetValue(event.target.value);
 };

 const handleHesaplaClick = async () => {
  try {
   setLoading(true);
   const response = await Network.post("d/boya/uygulanan/aksam", {
    ard: stateValue,
    adet: adetValue,
   });
   setResult(response);
   console.log(response);
  } catch (error) {
   console.error("Error:", error);
  } finally {
   setLoading(false); 
  }
 };

 return (
  <div className="container-fluid">
   <div className="row">
    <Sidebar />
    <div className="col-9 px-0">
     <div className="navbar">
      <div className="navbar-title">BOYA UYGULANAN AKSAM</div>
     </div>
     <div className="card-1">
      <div className="centered-inputs">
       <label className="input-label">
        Bu işlemde ne kadar ard ve adet kullanımı olduğunu giriniz.
       </label>
       <div className="input-group">
         <input
          type="number"
          placeholder="Ard"
          value={stateValue}
          onChange={handleStateChange}
         />
         <input
          type="number"
          placeholder="Adet"
          value={adetValue}
          onChange={handleAdetChange}
         />
       </div>
       <button
        className="calculate-button"
        onClick={handleHesaplaClick}
        disabled={loading} 
       >
         {loading ? "Hesaplanıyor..." : "Hesapla"}
       </button>
       {result !== null && <p className="result">Hesabın Sonucu: {result}</p>}
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default BoyaUygulananAksam;
