import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import BoyaUygulananAksam from './pages/boyaUygulananAksam'
import ParcalardaDegisim from './pages/parcalardaDegisim'
import ParcalardaDuzeltme from './pages/parcalardaDüzeltme'
import YerAlmayanParcalar from './pages/yerAlmayanParcalar'


const App = () => {
    return (
      
        <Router>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous"></link>
            <Routes>
                <Route path="/" exact element={<ParcalardaDegisim/>}/>
                <Route path="/parcalardaDuzeltme" exact element={<ParcalardaDuzeltme/>}/>
                <Route path="/yerAlmayanParcalar" exact element={<YerAlmayanParcalar/>}/>
                <Route path="/boyaUygulananAksam" exact element={<BoyaUygulananAksam/>}/>
            </Routes>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>
        </Router>
    )
}

export default App